import {Controller} from 'stimulus';
import {I18n} from '../translation';

export default class extends Controller {
  static targets = ['sideNav', 'title', 'logo', 'logoWeb', 'icon', 'navChildrenList', 'navListTitle'];
  navChildren = false;
  sideNavBanner = document.getElementById('side-nav-banner');

  connect() {
    this.setActiveTab();
    this.logoTarget.classList.add('hidden');
    this.closeSidenav();
  }

  toggleSidenav() {
    this.logoWebTarget.classList.remove('hidden');
    this.logoTarget.classList.add('hidden');
    this.sideNavBanner.classList.remove('hidden');
    document.getElementById('side-nav').style.width = '18%';
    document.getElementById('side-nav').style.transition = '0.4s';
    document.getElementById('main-content').style.width = '82%';
    this.navChildrenListTargets.forEach(el => el.classList.replace('pl-[8px]', 'pl-[40px]'));
    this.navListTitleTargets.forEach(element => element.style.display = 'block');
    document.querySelectorAll('#children-nav-dropdown').forEach(ele => {
      ele.style.display = 'block';
    });
  }

  closeSidenav() {
    this.logoWebTarget.classList.add('hidden');
    this.logoTarget.classList.remove('hidden');
    this.sideNavBanner.classList.add('hidden');
    this.sideNavTarget.classList.replace('w-[250px]', 'w-[64px]');
    document.getElementById('side-nav').style.width = '5%';
    document.getElementById('main-content').style.width = '95%';
    this.navChildrenListTargets.forEach(el => el.classList.replace('pl-[40px]', 'pl-[8px]'));
    this.navListTitleTargets.forEach(element => element.style.display = 'none');
    document.querySelectorAll('#children-nav-dropdown').forEach(ele => {
      ele.style.display = 'none';
    });
  }

  openNavChildren(event) {
    event.currentTarget.dataset.extension = event.currentTarget.dataset.extension === 'closed' ? 'opened' : 'closed';
    // TODO BE:document.getElementById('navChildren').classList.toggle('hidden');
    document.getElementById(event.currentTarget.dataset.childrenNavId).classList.toggle('hidden');
  }

  setContentHeader(event) {
    if (event.currentTarget.dataset.content !== 'Logout') {
      document.getElementById('content-header').innerText = event.currentTarget.dataset.content;

      [...document.getElementsByClassName('bg-blue/[0.08] text-blue')].forEach(ele => {
        ele.classList.remove('bg-blue/[0.08]', 'text-blue');
      });

      if (event.currentTarget.dataset.content.includes('People')) {
        document.getElementById(`people_${event.currentTarget.dataset.tab}`).classList.add('bg-blue/[0.08]', 'text-blue');
      } else if (event.currentTarget.dataset.content.includes('Promotions')) {
        document.getElementById(`promo_${event.currentTarget.dataset.tab}`).classList.add('bg-blue/[0.08]', 'text-blue');
      } else {
        document.getElementById(event.currentTarget.dataset.tab).classList.add('bg-blue/[0.08]', 'text-blue');
      }
    }
  }

  setActiveTab() {
    const currentPath = window.location.href;

    if (currentPath.includes('users')) {
      document.getElementById('people').click();
      this.updateSideView({ navId: 'people_users', translation: 'people' });
    } else if (currentPath.includes('roles')) {
      document.getElementById('people').click();
      this.updateSideView({ navId: 'people_roles', translation: 'people' });
    } else if (currentPath.includes('cms')) {
      this.updateSideView({ navId: 'cms', translation: 'cms' });
    } else if (currentPath.includes('product_configurator')) {
      document.getElementById('pc').click();
      this.updateSideView({ navId: 'pc_product_configurator', translation: 'product_configurator' });
    } else if (currentPath.includes('internal_categories')) {
      document.getElementById('pc').click();
      this.updateSideView({ navId: 'pc_internal_categories', translation: 'internal_categories' });
    } else if (currentPath.includes('categories')) {
      document.getElementById('pc').click();
      this.updateSideView({ navId: 'pc_categories', translation: 'categories' });
    } else if (currentPath.includes('inventories')) {
      this.updateSideView({ navId: 'im', translation: 'inventory_management' });
    } else if (currentPath.includes('discount')) {
      document.getElementById('promo').click();
      this.updateSideView({ navId: 'promo_discount', translation: 'promotion' });
    } else if (currentPath.includes('coupons')) {
      document.getElementById('promo').click();
      this.updateSideView({ navId: 'promo_coupons', translation: 'promotion' });
    } else if (currentPath.includes('brand')) {
      this.updateSideView({ navId: 'brand', translation: 'brands' });
    } else if (currentPath.includes('retailer')) {
      this.updateSideView({ navId: 'retailers', translation: 'retailers' });
    } else if(currentPath.includes('search_engine_values')){
      this.updateSideView({ navId: 'pc_product_configurator', translation: 'seo_management' });
    } else if(currentPath.includes('tags')){
      this.updateSideView({ navId: 'pc_product_configurator', translation: 'product_tags' });
    } else if(currentPath.includes('reports')){
      this.updateSideView({ navId: 'pc_product_configurator', translation: 'reports' });
    } else if(currentPath.includes('gift_cards')){
      document.getElementById('promo').click();
      this.updateSideView({ navId: 'promo_gift_card', translation: 'gift_card_detail' });
    } else if(currentPath.includes('taxons')){
      this.updateSideView({navId: 'pc_categories', translation: 'sub_categories' });
    } else {
      this.updateSideView({ navId: 'order-mngmnt', translation: 'order_management' });
    }
  }

  updateSideView({ navId, translation }) {
    document.getElementById('content-header').innerText = I18n[window.currentLocale][translation];
    document.getElementById(navId).classList.add('bg-blue/[0.08]', 'text-blue');
  }
}
