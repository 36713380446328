import {Controller} from 'stimulus';
import Cropper from 'cropperjs';
import {updateProfilePicture} from '../services/user_profile';
import {getPhotoUrl} from "../services/api/blob";

export default class extends Controller {

  crop(event) {
    document.getElementById('admin_profile_image_crop').src = '';
    let loaderDom = document.getElementById('admin_img-loader');
    loaderDom.parentElement.classList.remove('img-gradient');
    loaderDom.style.display = 'none';

    document.getElementById('admin_profile_image_cropper').click();
    const image = document.getElementById('admin_profile_image_crop');
    const file = event.target.files?.[0];
    const url = URL.createObjectURL(file);
    image.src = url;
    image.onload = () => {
      this.cropImage(image, event);
    };
  }

  cropImage(image) {
    // eslint-disable-next-line no-unused-vars
    let _this = this;
    let croppedData;
    const cropper = new Cropper(image, {
      aspectRatio: 1 / 1,
      restore: true,
      zoomable: false,
      viewMode: 1,
      crop(event) {
        croppedData = {
          width: event.detail.width,
          height: event.detail.height
        };
      },
      ready() {
        document.getElementById('admin_crop-btn').addEventListener('click', () => {
          let loaderDom = document.getElementById('admin_img-loader');
          loaderDom.parentElement.classList.add('img-gradient');
          loaderDom.style.display = 'block';
          const canvas = cropper.getCroppedCanvas();
          const image = document.getElementById('admin_profile_image_crop');
          _this.loadXHR(canvas.toDataURL()).then((imgBlob) => {
            getPhotoUrl(imgBlob, (resp) => {
              image.src = resp.url;
              cropper.destroy();
              loaderDom.style.display = 'none';
              loaderDom.parentElement.classList.remove('img-gradient');
              document.getElementById('admin-img-save').disabled = false;
            });
          });
        });
        document.getElementById('admin_rotate-btn').addEventListener('click', () => {
          cropper.rotate(90);
        });
      },
    });
  }

  saveImage(e) {
    let email = e.currentTarget.dataset.userEmail;
    let loaderDom = document.getElementById('admin_img-loader');
    loaderDom.parentElement.classList.add('img-gradient');
    loaderDom.style.display = 'block';

    const imgEl = document.getElementById('admin_profile_image_crop');
    const imgUrl = imgEl.src;
    updateProfilePicture(imgUrl, email, function () {
      const profilePicture = document.getElementById('profile_picture');
      let navBarMugshot = document.getElementById('navbar-mugshot');
      profilePicture.src = imgUrl;
      if (navBarMugshot) {
        navBarMugshot.src = imgUrl;
      }
      document.getElementById('admin_profile_picture_cancel').click();
    });
  }

  loadXHR(url) {
    return new Promise(function (resolve, reject) {
      try {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.onerror = function () {
          reject('Network error.');
        };
        xhr.onload = function () {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject('Loading error:' + xhr.statusText);
          }
        };
        xhr.send();
      } catch (err) {
        reject(err.message);
      }
    });
  }

  cancelUpload() {
    document.getElementById('admin_crop-btn').click();
  }
}