export const cartPriceAndItemReplaceDom = function (response) {
  let itemDom = document.createElement('div');
  let priceDom = document.createElement('div');
  itemDom.innerHTML = response.item;
  priceDom.innerHTML = response.price;
  let couponDom = document.getElementById('coupon_div');
  if (couponDom) {
    couponDom.innerHTML = response.coupon;
  }
  let recommendationDom = document.getElementById('recommendation-dom');
  if (recommendationDom) {
    recommendationDom.innerHTML = response.recommendation;
  }
  let newPriceDomContent = priceDom.children[0];
  document.getElementById('cart_items')?.replaceWith(itemDom.children[0]);
  document.querySelectorAll('#cart_price').forEach((element) => {
    element.replaceWith(newPriceDomContent.cloneNode(true));
  });
};
