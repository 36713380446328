import {Controller} from "stimulus";
import {constructArrayParams} from "../services/construct_array_params";

export default class extends Controller {
  selectedCollection = [];

  collection_check(event) {
    let collectionCategoryName = event.currentTarget.dataset.category;
    let collectionProductType = event.currentTarget.dataset.productType;
    let collectionProductCount = event.currentTarget.dataset.productCount;
    let collectionTaxonID = event.currentTarget.dataset.taxonId;
    if (event.currentTarget.checked) {
      this.addCollection(`${collectionTaxonID}_${collectionProductType}`);
      this.selectedCollectionList(collectionTaxonID, collectionCategoryName, collectionProductType, collectionProductCount, 'add');
    } else {
      this.removeCollection(`${collectionTaxonID}_${collectionProductType}`);
      this.selectedCollectionList(collectionTaxonID, collectionCategoryName, collectionProductType, collectionProductCount, 'remove');
    }
    this.submittable();
  }

  addCollection(collection) {
    if (!this.selectedCollection.includes(collection)) {
      this.selectedCollection.push(collection);
    }
  }

  removeCollection(collection) {
    if (this.selectedCollection.includes(collection)) {
      this.selectedCollection.splice(this.selectedCollection.indexOf(collection), 1);
    }
  }

  submittable() {
    this.selectedCollection.length > 0 ? this.enableSubmitButton() : this.disableSubmitButton();
  }

  enableSubmitButton() {
    document.getElementById('discount_collection_submit_button').disabled = false;
  }

  disableSubmitButton() {
    document.getElementById('discount_collection_submit_button').disabled = true;
  }

  selectedCollectionList(id, name, productType, count, method) {
    method === 'add' ? this.addCollectionInSelectedList(id, name, productType, count) : this.removeCollectionFromSelectedList(id, productType);
  }

  addCollectionInSelectedList(id, name, productType, count) {
    let vDom = document.createElement('div');
    vDom.className = 'rounded-full bg-gray-100 flex align-start cursor-pointer w-fit hover:bg-blue/[0.08] mb-[12px] items-center px-3 py-2 text-[12px] text-black/[0.87] capitalize';
    vDom.id = `selected_collection_${productType}_${id}`;
    let pTag = document.createElement("p");
    pTag.innerHTML = `${name} | ${productType} | ${count}`;
    let icon = document.createElement('i');
    icon.className = 'material-icons-outlined !text-[12px] pl-[2px] text-black/[0.6]';
    icon.dataset.action="click->promotion-collection-selection#removeSelectedCollection";
    icon.dataset.taxonId = id;
    icon.dataset.productType = productType;
    icon.innerHTML = 'close';
    vDom.appendChild(pTag);
    vDom.appendChild(icon);
    document.getElementById('selected_discount_collection_list_div').appendChild(vDom);
  }

  removeCollectionFromSelectedList(id, productType) {
    document.getElementById(`selected_collection_${productType}_${id}`).remove();
  }

  removeSelectedCollection(event) {
    let productType = event.currentTarget.dataset.productType;
    let taxon_id = event.currentTarget.dataset.taxonId;
    if (document.getElementById(`discount_collection_checkbox_${productType}_${taxon_id}`)) {
      document.getElementById(`discount_collection_checkbox_${productType}_${taxon_id}`).click();
    } else {
      this.removeCollection(`${taxon_id}_${productType}`);
      document.getElementById(`selected_collection_${productType}_${taxon_id}`).remove();
    }
    this.submittable();
  }

  addCollectionDiscount(event) {
    let url = `/admin/promotions/collection_selection?${constructArrayParams(this.selectedCollection, 'selectedProduct')}`;
    fetch(url).then(response => {
      response.text().then(text => {
        document.getElementById('promotion_listing').innerHTML = text;
      });
    });
  }

  updatePromotion(event) {
    document.getElementById('promotion_collection_id').value = this.selectedCollection;
    document.getElementById('promotion_collection_submit_button').click();
  }
}
